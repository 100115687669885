import { useQuery, gql } from "@apollo/client";
import { FolderOpen, InsertDriveFile, InsertPhoto, Videocam } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import ItemViewer from "./ItemViewer";

const LISTING = gql`
  query Listing($path: String) {
    listing(path: $path) {
      itemKey
      name
      size
      createdAtUtc
      duration
      itemFileType
    }
  }
`;

interface Item {
  itemKey: string;
  name: string;
  size?: number;
  createdAtUtc?: string;
  duration?: number;
  itemFileType: ItemFileType;
}

export enum ItemFileType {
  FOLDER = "FOLDER",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  OTHER = "OTHER"
}

type Order = "asc" | "desc";

export default function FileBrowserPage() {
  const { "*": splat } = useParams();
  const { loading, error, data } = useQuery<{ listing: Item[] }, { path: string }>(LISTING, { variables: { path: splat ?? "" } });
  const [selectedImage, setSelectedImage] = useState<string>();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Item>("itemFileType");

  const sortedData = useMemo(() => {
    // default is Folders first, then Files
    if (orderBy === "itemFileType") {
      return _.sortBy(data?.listing, [(o) => (o.itemFileType === ItemFileType.FOLDER ? 1 : 2), (o) => o.name]);
    }
    return _.orderBy(data?.listing, orderBy, order);
  }, [data, order, orderBy]);

  const ItemRow = (props: { item: Item }) => {
    const FileIcon = () => {
      if (props.item.itemFileType === ItemFileType.VIDEO) {
        return <Videocam fontSize="small" />;
      }

      if (props.item.itemFileType === ItemFileType.IMAGE) {
        return <InsertPhoto fontSize="small" />;
      }

      return <InsertDriveFile fontSize="small" />;
    };

    const formatDuration = (duration?: number) => {
      if (duration === undefined || duration === null || duration === 0) {
        return "";
      }

      var hours = Math.floor(duration / 3600);
      var minutes = Math.floor((duration - hours * 3600) / 60);
      var seconds = duration - hours * 3600 - minutes * 60;

      return (hours < 10 ? `0${hours}` : hours) + ":" + (minutes < 10 ? `0${minutes}` : minutes) + ":" + (seconds < 10 ? `0${seconds}` : seconds);
    };

    const formatFileSize = (fileSizeBytes?: number) => {
      if (fileSizeBytes === undefined) {
        return "";
      }

      if (fileSizeBytes > 1073741824) {
        return (fileSizeBytes / 1073741824).toFixed(2) + " GB";
      }
      if (fileSizeBytes > 1048576) {
        return (fileSizeBytes / 1048576).toFixed(2) + " MB";
      }
      if (fileSizeBytes > 1024) {
        return (fileSizeBytes / 1024).toFixed(2) + " KB";
      }
      return fileSizeBytes + " B";
    };

    if (props.item.itemFileType === ItemFileType.FOLDER) {
      return (
        <TableRow key={props.item.itemKey} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell width="2em" sx={{ minWidth: "2em", maxWidth: "2em" }} align="left">
            <FolderOpen fontSize="small" />
          </TableCell>
          <TableCell width="55%" sx={{ minWidth: "55%", maxWidth: "55%" }} align="left">
            <Link component={RouterLink} underline="hover" to={"/file-browser/" + props.item.itemKey}>
              {props.item.name}
            </Link>
          </TableCell>
          <TableCell colSpan={3} align="right"></TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow key={props.item.itemKey} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell width="2em" sx={{ minWidth: "2em", maxWidth: "2em" }} align="left">
          <FileIcon />
        </TableCell>
        <TableCell width="55%" align="left">
          <Link component="div" underline="hover" onClick={() => setSelectedImage(props.item.itemKey)} style={{ cursor: "pointer" }}>
            {props.item.name}
          </Link>
        </TableCell>
        <TableCell width="15%" align="left">
          {props.item.createdAtUtc === undefined ? "" : format(parseISO(props.item.createdAtUtc), "y/MM/dd HH:mm")}
        </TableCell>
        <TableCell width="15%" align="right">
          {formatDuration(props.item.duration)}
        </TableCell>
        <TableCell width="15%" align="right">
          {formatFileSize(props.item.size)}
        </TableCell>
      </TableRow>
    );
  };

  const NavBreadcrumbs = () => {
    const splatComponents = splat?.split("/").filter((s) => s !== "");
    if (splatComponents === undefined || splatComponents?.length === 0) {
      return (
        <Breadcrumbs>
          <Typography>Top</Typography>
        </Breadcrumbs>
      );
    }

    const breadcrumbLinks = splatComponents?.map((fragment, index, array) =>
      index === array.length - 1 ? (
        <Typography>{fragment}</Typography>
      ) : (
        <Link key={index} component={RouterLink} underline="hover" to={array.slice(0, index + 1).join("/") + "/"}>
          {fragment}
        </Link>
      )
    );
    return (
      <Breadcrumbs>
        <Link component={RouterLink} underline="hover" to="/file-browser">
          Top
        </Link>
        {breadcrumbLinks}
      </Breadcrumbs>
    );
  };

  if (error) {
    return <h1>Error: {error.message}</h1>;
  }

  const createSortHandler = (property: keyof Item) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Item) => {
    if (orderBy !== property) {
      setOrder("asc");
      setOrderBy(property);
      return;
    }
    // if user clicks the same property from DESC, reset the sort order to Folders on top, then Files
    if (order === "desc") {
      setOrder("asc");
      setOrderBy("itemFileType");
      return;
    }
    setOrder("desc");
  };

  return (
    <React.Fragment>
      {loading ? (
        <Dialog open>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      ) : null}
      <Container maxWidth="xl">
        <Box sx={{ margin: 1, marginTop: 2, marginBottom: 2 }}>
          <NavBreadcrumbs />
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="2em" sx={{ minWidth: "2em", maxWidth: "2em" }} align="left"></TableCell>
                <TableCell width="50%" sx={{ minWidth: "55%", maxWidth: "55%" }} align="left">
                  <TableSortLabel onClick={createSortHandler("name")} active={orderBy === "name"} direction={orderBy === "name" ? order : "asc"}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell width="15%" sx={{ minWidth: "15%" }} align="left">
                  <TableSortLabel
                    hideSortIcon
                    onClick={createSortHandler("createdAtUtc")}
                    active={orderBy === "createdAtUtc"}
                    direction={orderBy === "createdAtUtc" ? order : "asc"}
                  >
                    Created
                  </TableSortLabel>
                </TableCell>
                <TableCell width="15%" sx={{ minWidth: "15%" }} align="right">
                  <TableSortLabel
                    hideSortIcon
                    onClick={createSortHandler("duration")}
                    active={orderBy === "duration"}
                    direction={orderBy === "duration" ? order : "asc"}
                  >
                    Duration
                  </TableSortLabel>
                </TableCell>
                <TableCell width="15%" sx={{ minWidth: "15%" }} align="right">
                  <TableSortLabel
                    hideSortIcon
                    onClick={createSortHandler("size")}
                    active={orderBy === "size"}
                    direction={orderBy === "size" ? order : "asc"}
                  >
                    Size
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((i) => (
                <ItemRow key={i.itemKey} item={i} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <p></p>
      </Container>
      {selectedImage ? <ItemViewer itemKey={selectedImage} onClose={() => setSelectedImage(undefined)} /> : null}
    </React.Fragment>
  );
}
