import { gql, useQuery } from "@apollo/client";
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ItemFileType } from "./FileBrowserPage";

const GET_ITEM_URL = gql`
  query GetItemUrl($itemKey: String) {
    item(itemKey: $itemKey) {
      itemFileType
      name
      signedUrl
    }
  }
`;

export default function ItemViewer(props: { itemKey: string; onClose: any }) {
  const { loading, error, data } = useQuery<{ item: { itemFileType: ItemFileType; signedUrl: string; name: string } }, { itemKey: string }>(
    GET_ITEM_URL,
    {
      variables: { itemKey: props.itemKey }
    }
  );

  const ViewerDialogContent = () => {
    if (loading) {
      return <CircularProgress color="primary" sx={{ padding: 4 }} />;
    }

    if (error) {
      return <div>Error</div>;
    }

    if (data !== undefined && data.item !== undefined) {
      if (data.item.itemFileType === ItemFileType.IMAGE) {
        return (
          <a href={data.item.signedUrl} rel="noopener noreferrer" target="_blank">
            <img alt="" src={data.item.signedUrl} style={{ width: "100%", maxHeight: "90vh", objectFit: "contain" }} />
          </a>
        );
      }
      if (data.item.itemFileType === ItemFileType.VIDEO) {
        return (
          <video
            src={data.item.signedUrl}
            style={{ width: "100%! important", maxHeight: "90vh", height: "auto", objectFit: "scale-down" }}
            controls={true}
            autoPlay={true}
          />
        );
      }
      return <div>Unknown format.</div>;
    }
    return null;
  };

  return (
    <Dialog maxWidth="xl" fullWidth={true} open={true} scroll="body" onClose={props.onClose}>
      <DialogTitle>
        {data?.item?.name}
        <IconButton onClick={props.onClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", overflow: "hidden", padding: 0, margin: 0 }}>
        <ViewerDialogContent />
      </DialogContent>
    </Dialog>
  );
}
