// I stole this from GDF Dashboard: https://github.com/fugro/fugro.geodatafactory.dashboard/blob/master/src/LocalTheme.tsx

import React, { ReactNode } from "react";
import { createTheme, ThemeProvider, StyledEngineProvider, PaletteOptions } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  DarkFoundationsNeutralNeutral20,
  DarkFoundationsNeutralNeutral50,
  DarkFoundationsNeutralNeutral80,
  DarkInteractive,
  DarkNegative,
  DarkPositive,
  DarkStateDisabled1,
  DarkSupport1,
  DarkSurfaceBackground,
  DarkSurfaceSurfaceElevation1,
  DarkSurfaceSurfaceElevation2,
  DarkSurfaceSurfaceElevation3,
  DarkSurfaceSurfaceElevation4,
  DarkSurfaceSurfaceElevation5,
  DarkSurfaceSurfaceHighlight,
  DarkSurfaceSurfaceInteractive,
  DarkSurfaceSurfaceNegative,
  DarkSurfaceSurfacePositive,
  DarkSurfaceSurfaceWarning,
  DarkTextText1,
  DarkTextTextInteractive,
  DarkWarning
} from "@fugro/fugro.globaldesignsystem.designtokens/dist/js/variables";

// Add our extra fields to the types.
// This is called 'module augmentation'.
declare module "@mui/material/styles" {
  interface TypeText {
    /**
     * Used for interactive text, when it's done like a text or outlined button.
     */
    interactive: TypeText["primary"];
    /**
     * Used for rather subtle text.
     */
    tertiary: TypeText["primary"];
    /**
     * Use for the title of a section/dialog/etc.
     */
    title: TypeText["primary"];
    /**
     * Use for text that should almost be unreadable, i.e. very subtle.
     */
    muted: TypeText["primary"];
  }

  interface TypeBackground {
    success: TypeBackground["paper"];
    error: TypeBackground["paper"];
    warning: TypeBackground["paper"];
    /**
     * Use when an element can be interacted with.
     *
     * This highlights the element, so sometimes might be mixed with an alpha(interactive, 0.3)
     * or similar to slightly dim it.
     */
    interactive: TypeBackground["paper"];
    /**
     * Use when an element is selected.
     */
    selected: TypeBackground["paper"];
    /**
     * The different elevations of backgrounds.
     * This would usually be tied to Paper but that's not always available.
     */
    levels: [
      TypeBackground["paper"],
      TypeBackground["paper"],
      TypeBackground["paper"],
      TypeBackground["paper"],
      TypeBackground["paper"],
      TypeBackground["paper"]
    ];
  }
}

// Values not defined by any design token.
const darkTitle = "#BFD0EB";
const darkScrollbar = "#6A707E";

const darkModePalette: PaletteOptions = {
  primary: {
    main: DarkInteractive
  },
  success: {
    main: DarkPositive
  },
  error: {
    main: DarkNegative
  },
  warning: {
    main: DarkWarning
  },
  info: {
    main: DarkSupport1
  },
  text: {
    primary: DarkTextText1,
    secondary: DarkFoundationsNeutralNeutral20,
    tertiary: DarkFoundationsNeutralNeutral50,
    muted: DarkFoundationsNeutralNeutral80,
    interactive: DarkTextTextInteractive,
    disabled: DarkStateDisabled1,
    title: darkTitle
  },
  background: {
    paper: DarkSurfaceSurfaceElevation2,
    success: DarkSurfaceSurfacePositive,
    error: DarkSurfaceSurfaceNegative,
    warning: DarkSurfaceSurfaceWarning,
    interactive: DarkSurfaceSurfaceInteractive,
    selected: DarkSurfaceSurfaceHighlight,
    levels: [
      DarkSurfaceBackground,
      DarkSurfaceSurfaceElevation1,
      DarkSurfaceSurfaceElevation2,
      DarkSurfaceSurfaceElevation3,
      DarkSurfaceSurfaceElevation4,
      DarkSurfaceSurfaceElevation5
    ]
  },
  divider: DarkSurfaceSurfaceInteractive
};

export const globalTheme = createTheme({
  palette: { mode: "dark", ...darkModePalette },
  // palette: { mode: "light", ...lightModePalette },
  typography: {
    fontFamily: "Inter, Arial, sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "20px",
      color: darkTitle,
      fontWeight: "600"
    },
    body1: {
      fontSize: 14
    },
    button: {
      textTransform: "none"
    }
  },
  mixins: {
    toolbar: {
      height: 80
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // Not that the browsers seem to respect the smoothing values.
        // They're active but they don't seem to do anything, even if they disable smoothing.
        body: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale"
        },

        // Replace the scrollbar so it's just a little bar, as best we can.
        // Without this Firefox picks a dark blue scrollbar (background was blue) which looks OK.
        // Webkit (Chrome) picks a dark grey scrollbar or possibly a light one depending on the Chrome theme,
        // that looks rather out of place.

        // For Firefox/non-Webkit:
        // The scrollbar gets a highlight when clicked or hovered.
        // The color of this is based on the given color, which does mean it ends up a little bright.
        // Without setting this, the background shows up which makes it looks less like Chrome.
        // Since it isn't _too_ bright, we live with it and that at least makes the pages look similar at rest.
        "*": {
          scrollbarWidth: "thin"
        },
        html: {
          scrollbarColor: `${darkScrollbar} rgba(0,0,0,0)`
        },

        // For Webkit:
        "*::-webkit-scrollbar": {
          height: "0.5em", // For horizontal scrollbars
          width: "0.5em", // For vertical scrollbars
          fontSize: "12px"
        },
        "*::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0)"
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: `${darkScrollbar} !important`,
          border: "none !important",
          borderRadius: 8
        },

        "& .SnackbarContainer-root": {
          // Make the message a bit bigger to be easier to read.
          "& div": {
            fontSize: "15px",
            borderRadius: "0px !important"
          },
          "& svg": {
            fontSize: "25px !important"
          }
        },
        "& .SnackbarContent-root": {
          backgroundColor: `${DarkSurfaceSurfaceElevation2} !important`
        },
        // Recolor some variants to match the theme.
        // Why don't these use (theme) => `${theme.palette.info.main} !important` or some variation?
        // Because, oddly, the '!important' is lost when doing that.
        // The notification itself uses !important so we need that to beat it.
        "& .SnackbarItem-variantInfo": {
          borderLeft: `5px solid ${DarkSupport1} !important`,
          "& .MuiSvgIcon-root": {
            color: `${DarkSupport1} !important`
          }
        },
        "& .SnackbarItem-variantError": {
          borderLeft: `5px solid ${DarkNegative} !important`,
          "& .MuiSvgIcon-root": {
            color: `${DarkNegative} !important`
          }
        },
        "& .SnackbarItem-variantSuccess": {
          borderLeft: `5px solid ${DarkPositive} !important`,
          "& .MuiSvgIcon-root": {
            color: `${DarkPositive} !important`
          }
        },
        "& .SnackbarItem-variantWarning": {
          borderLeft: `5px solid ${DarkWarning} !important`,
          "& .MuiSvgIcon-root": {
            color: `${DarkWarning} !important`
          }
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        // 'Interactive' tooltips are generally annoying in lists because they block you from hovering over the next item.
        // The tooltip only disappears when you move outside the bounds of the tooltip popup.
        // This does 'violate' a S3 WCAG21 guideline that hoverable content can be moused over without it disappearing.
        // There is generally a mechanism for copying the value, and we aren't overly concerned about screen readers.
        // We err on being less annoying for most users at the cost of being annoying for a few (none?) users.
        disableInteractive: true,

        // Having an arrow makes it clearer where the tooltip applies to.
        // This is especially relevant for lists (folder tree, folder contents table)
        // and for consistency it's applied everywhere as a general rule.
        // Anywhere this doesn't make sense can always override it.
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
          backgroundColor: DarkSurfaceSurfaceElevation4,
          boxShadow: "0px 4px 4px 0px #0000004d, 0px 8px 12px 6px #00000026",
          padding: "8px 12px" // The design calls for 12px but the text has a little vertical spacing itself, so reduce that padding.
        },
        arrow: {
          color: DarkSurfaceSurfaceElevation4,
          // The actual arrow head is a rotated rectangle, so need to target that to give it a shadow.
          // This does double-up on the shadow a little bit around the joins but it's pretty subtle.
          // Its box is clipped so a smaller drop shadow is used otherwise it gets clipped off.
          "&::before": {
            boxShadow: "0px 0px 4px 1px #00000026"
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined"
      },
      styleOverrides: {
        root: {
          borderRadius: "8px"
          // Default button styles pick up based on the 'color' prop.
          // Prefer that since it lets specific instances use 'error' or similar for buttons.
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiMenu-paper": {
            background: theme.palette.background.levels[3],
            minWidth: 244
          }
        })
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        // disablePortal necessary for styling selected item in list.
        // as autocomplete-option is not a child of autocomplete, we need to target it as an adjacent element.
        disablePortal: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("xl")]: {
            width: 500
          },
          [theme.breakpoints.down("xl")]: {
            width: 300
          },

          "& + .MuiAutocomplete-popper ": {
            "& .MuiAutocomplete-paper": {
              backgroundColor: theme.palette.background.levels[1]
            },
            "& .MuiAutocomplete-option": {
              lineBreak: "anywhere",
              '&[aria-selected="true"]': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.selected,
                "&.Mui-focused": {
                  backgroundColor: "rgba(75, 178, 249, 0.24)"
                }
              }
            }
          }
        })
      }
    }
  }
});

export function LocalTheme({ children }: { children?: ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={globalTheme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
