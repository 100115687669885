import { useQuery, gql } from "@apollo/client";
import { FolderOpen, InsertDriveFile, InsertPhoto, Videocam } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import ItemViewer from "./ItemViewer";

const SEARCH = gql`
  query Search($searchTerm: String) {
    search(searchTerm: $searchTerm) {
      itemKey
      name
      size
      createdAtUtc
      duration
      itemFileType
    }
  }
`;

interface Item {
  itemKey: string;
  name: string;
  size?: number;
  createdAtUtc?: string;
  duration?: number;
  itemFileType: ItemFileType;
}

export enum ItemFileType {
  FOLDER = "FOLDER",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  OTHER = "OTHER"
}

type Order = "asc" | "desc";

export default function SearchPage() {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("q") ?? "";
  const { loading, error, data } = useQuery<{ search: Item[] }, { searchTerm: string }>(SEARCH, { variables: { searchTerm: searchTerm } });
  const [selectedImage, setSelectedImage] = useState<string>();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Item>("itemFileType");

  const sortedData = useMemo(() => {
    // default is Folders first, then Files
    if (orderBy === "itemFileType") {
      return _.sortBy(data?.search, [(o) => (o.itemFileType === ItemFileType.FOLDER ? 1 : 2), (o) => o.name]);
    }
    return _.orderBy(data?.search, orderBy, order);
  }, [data, order, orderBy]);

  const ItemRow = (props: { item: Item }) => {
    const FileIcon = () => {
      if (props.item.itemFileType === ItemFileType.VIDEO) {
        return <Videocam fontSize="small" />;
      }

      if (props.item.itemFileType === ItemFileType.IMAGE) {
        return <InsertPhoto fontSize="small" />;
      }

      return <InsertDriveFile fontSize="small" />;
    };

    const formatDuration = (duration?: number) => {
      if (duration === undefined || duration === null || duration === 0) {
        return "";
      }

      var hours = Math.floor(duration / 3600);
      var minutes = Math.floor((duration - hours * 3600) / 60);
      var seconds = duration - hours * 3600 - minutes * 60;

      return (hours < 10 ? `0${hours}` : hours) + ":" + (minutes < 10 ? `0${minutes}` : minutes) + ":" + (seconds < 10 ? `0${seconds}` : seconds);
    };

    const formatFileSize = (fileSizeBytes?: number) => {
      if (fileSizeBytes === undefined) {
        return "";
      }

      if (fileSizeBytes > 1073741824) {
        return (fileSizeBytes / 1073741824).toFixed(2) + " GB";
      }
      if (fileSizeBytes > 1048576) {
        return (fileSizeBytes / 1048576).toFixed(2) + " MB";
      }
      if (fileSizeBytes > 1024) {
        return (fileSizeBytes / 1024).toFixed(2) + " KB";
      }
      return fileSizeBytes + " B";
    };

    if (props.item.itemFileType === ItemFileType.FOLDER) {
      return (
        <TableRow key={props.item.itemKey} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell width="2em" sx={{ minWidth: "2em", maxWidth: "2em" }} align="left">
            <FolderOpen fontSize="small" />
          </TableCell>
          <TableCell width="50%" sx={{ minWidth: "50%", maxWidth: "50%" }} align="left">
            <div>
              <Link component={RouterLink} underline="hover" to={"/file-browser/" + props.item.itemKey}>
                {props.item.name}
              </Link>
            </div>
            <Typography variant="caption">in {props.item.itemKey.replace(props.item.name, "")}</Typography>
          </TableCell>
          <TableCell colSpan={3} align="right"></TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow key={props.item.itemKey} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell width="2em" sx={{ minWidth: "2em", maxWidth: "2em" }} align="left">
          <FileIcon />
        </TableCell>
        <TableCell width="50%" align="left">
          <div>
            <Link component="button" underline="hover" onClick={() => setSelectedImage(props.item.itemKey)}>
              {props.item.name}
            </Link>
          </div>
          <Typography variant="caption" color={"GrayText"}>
            in {props.item.itemKey.replace(props.item.name, "")}
          </Typography>
        </TableCell>
        <TableCell width="20%" align="left">
          {props.item.createdAtUtc === undefined ? "" : format(parseISO(props.item.createdAtUtc), "y/MM/dd HH:mm")}
        </TableCell>
        <TableCell width="15%" align="right">
          {formatDuration(props.item.duration)}
        </TableCell>
        <TableCell width="15%" align="right">
          {formatFileSize(props.item.size)}
        </TableCell>
      </TableRow>
    );
  };

  const NavBreadcrumbs = () => {
    return (
      <Breadcrumbs>
        <Link component={RouterLink} underline="hover" to="/file-browser">
          Top
        </Link>
        <Typography>Search</Typography>
      </Breadcrumbs>
    );
  };

  if (error) {
    return <h1>Error: {error.message}</h1>;
  }

  const createSortHandler = (property: keyof Item) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Item) => {
    if (orderBy !== property) {
      setOrder("asc");
      setOrderBy(property);
      return;
    }
    // if user clicks the same property from DESC, reset the sort order to Folders on top, then Files
    if (order === "desc") {
      setOrder("asc");
      setOrderBy("itemFileType");
      return;
    }
    setOrder("desc");
  };

  return (
    <React.Fragment>
      {loading ? (
        <Dialog open>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      ) : null}
      <Container maxWidth="lg">
        <Box sx={{ margin: 1, marginTop: 2, marginBottom: 2 }}>
          <NavBreadcrumbs />
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="2em" sx={{ minWidth: "2em", maxWidth: "2em" }} align="left"></TableCell>
                <TableCell width="50%" sx={{ minWidth: "50%", maxWidth: "50%" }} align="left">
                  <TableSortLabel onClick={createSortHandler("name")} active={orderBy === "name"} direction={orderBy === "name" ? order : "asc"}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell width="20%" sx={{ minWidth: "20%" }} align="left">
                  <TableSortLabel
                    hideSortIcon
                    onClick={createSortHandler("createdAtUtc")}
                    active={orderBy === "createdAtUtc"}
                    direction={orderBy === "createdAtUtc" ? order : "asc"}
                  >
                    Created
                  </TableSortLabel>
                </TableCell>
                <TableCell width="15%" sx={{ minWidth: "15%" }} align="right">
                  <TableSortLabel
                    hideSortIcon
                    onClick={createSortHandler("duration")}
                    active={orderBy === "duration"}
                    direction={orderBy === "duration" ? order : "asc"}
                  >
                    Duration
                  </TableSortLabel>
                </TableCell>
                <TableCell width="15%" sx={{ minWidth: "15%" }} align="right">
                  <TableSortLabel
                    hideSortIcon
                    onClick={createSortHandler("size")}
                    active={orderBy === "size"}
                    direction={orderBy === "size" ? order : "asc"}
                  >
                    Size
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((i) => (
                <ItemRow key={i.itemKey} item={i} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {selectedImage ? <ItemViewer itemKey={selectedImage} onClose={() => setSelectedImage(undefined)} /> : null}
    </React.Fragment>
  );
}
