import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import FileBrowserPage from "./pages/FileBrowserPage";
import SearchPage from "./pages/SearchPage";
import { Box } from "@mui/material";
import { LocalTheme } from "./LocalTheme";
import TopAppBar from "./components/TopAppBar";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,

    userPoolId: process.env.REACT_APP_AUTH_COGNITO_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_COGNITO_USERPOOLWEBCLIENTID,

    mandatorySignIn: true,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  }
});

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_BASEURL + "/graphql" });

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default function App() {
  return (
    <React.StrictMode>
      <LocalTheme>
        <Authenticator.Provider>
          <ApolloProvider client={apolloClient}>
            <BrowserRouter>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <TopAppBar />
                <Box component="main" sx={{ flexGrow: 1, marginTop: "80px" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
                    <Authenticator hideSignUp={true} variation="modal">
                      <Routes>
                        <Route>
                          <Route path="/file-browser/*" element={<FileBrowserPage />} />
                          <Route path="/search" element={<SearchPage />} />
                          <Route index element={<Navigate to="/file-browser" replace />} />
                        </Route>
                      </Routes>
                    </Authenticator>
                  </Box>
                </Box>
              </Box>
            </BrowserRouter>
          </ApolloProvider>
        </Authenticator.Provider>
      </LocalTheme>
    </React.StrictMode>
  );
}
