import React, { useState } from "react";
import { AppBar, Toolbar, Stack, Typography, Avatar, Link, styled, alpha, InputBase, IconButton } from "@mui/material";
import { Search as IconSearch } from "@mui/icons-material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";

const AuthBlock = () => {
  const { user, signOut, authStatus } = useAuthenticator((context) => [context.user, context.signOut, context.authStatus]);

  if (authStatus !== "authenticated") {
    return <React.Fragment />;
  }

  return (
    <Stack direction="row" spacing={1}>
      <Avatar></Avatar>
      <Stack direction="column">
        <Typography>{user.attributes?.email ?? ""}</Typography>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            signOut({ global: true });
          }}
          sx={{ textAlign: "left" }}
        >
          Sign Out
        </Link>
      </Stack>
    </Stack>
  );
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: 0,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto"
  }
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50ch"
    }
  }
}));

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchParams] = useSearchParams();
  if (searchTerm === undefined && searchParams.get("q") !== null) {
    setSearchTerm(searchParams.get("q") ?? "");
  }

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/search?q=${searchTerm}`);
  };

  return (
    <React.Fragment>
      <form style={{ display: "inherit" }} onSubmit={handleSearchClick}>
        <Search>
          <StyledInputBase
            name="q"
            value={searchTerm}
            onChange={handleChange}
            placeholder="Search"
            inputProps={{ "aria-label": "search", fullWidth: true }}
          />
        </Search>
        <IconButton type="submit">
          <IconSearch />
        </IconButton>
      </form>
    </React.Fragment>
  );
};

export default function TopAppBar() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  return (
    <AppBar position="fixed" sx={{ justifyContent: "center", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Stack direction="row" alignItems="center" flex={1}>
          <Stack direction="column">
            <Typography variant="h6" component="div">
              <span>Sense.Insights</span>
              <span> / </span>
              <span> Video Archive </span>
            </Typography>
          </Stack>
        </Stack>
        {authStatus === "authenticated" ? <SearchBox /> : ""}
        <Stack direction="row-reverse" flex={1}>
          <AuthBlock />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
